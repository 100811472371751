import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBAnimation, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBTestimonial } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import Card from '../../components/card'
import CardIcons from '../../components/cardIcons.js'
import Cta from '../../components/cta'


export default ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <h2 className="font-alt font-w-700 title-xs-medium title-large pb-4">
              {post.frontmatter.howhelp.title}
            </h2>
            <h4 className="font-w-400 text-medium">
              {post.frontmatter.howhelp.subtitle}
            </h4>
            {post.frontmatter.howhelp.custservice && (
              <MDBRow className="pt-5">
                <>
                  {post.frontmatter.howhelp.custservice.map(
                    (custservices, index) => {
                      return (
                        <CardIcons
                          key={index}
                          collg="4"
                          colmd="6"
                          title={custservices.title}
                          imageUrl={custservices.image.childImageSharp.fixed}
                          imageAltText={custservices.alttext}
                        />
                      )
                    }
                  )}
                </>
              </MDBRow>
            )}
          </MDBContainer>
        </MDBAnimation>
      </section>

      <section className="bg-blue-green-gradient">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <Cta
              ctatitle={post.frontmatter.cta.title}
              ctadescription={post.frontmatter.cta.description}
              ctalink={post.frontmatter.cta.link}
              ctalinktext={post.frontmatter.cta.linktext}
            />
          </MDBContainer>
        </MDBAnimation>
      </section>

      <section className="bg-gray-light">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <h3 className="font-alt text-left font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium">
              {post.frontmatter.supportservices.title}
            </h3>
            <h4 className="font-alt text-left font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium">
              {post.frontmatter.supportservices.subtitle}
            </h4>
            {post.frontmatter.supportservices.info && (
              <MDBRow className="pt-5">
                <>
                  {post.frontmatter.supportservices.info.map((info, index) => {
                    return (
                      <Card
                        key={index}
                        collg="4"
                        colmd="6"
                        height="16rem"
                        title={info.title}
                        subtitle={info.description}
                        subclass="font-w-400 text-xs-medium text-medium-small mt-2"
                        description={info.linktext}
                        imageUrl={info.image.childImageSharp.fluid}
                        imageAltText={info.alttext}
                        placement={info.placement}
                        link={info.link}
                        descriptionClass="text-card-small"
                      />
                    )
                  })}
                </>
              </MDBRow>
            )}
          </MDBContainer>
        </MDBAnimation>
      </section>

      {post.frontmatter.quotes && (
        <section className="blockback">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBCarousel activeItem={1} length={post.frontmatter.quotes.length} testimonial showControls={false} showIndicators={true} className="no-flex">
              <MDBCarouselInner>
                {post.frontmatter.quotes.map((quote, index) => {
                  return (
                    <MDBCarouselItem itemId={index + 1}>
                      <MDBTestimonial>
                        <div className="blockquote-wrapper">
                          <div className="blockquote">
                            <h2>{quote.text}</h2>
                            {quote.name && (
                              <p style={{ whiteSpace: 'pre-wrap' }}>
                                {quote.name}
                              </p>
                            )}
                          </div>
                        </div>
                      </MDBTestimonial>
                    </MDBCarouselItem>
                  )
                })}
              </MDBCarouselInner>
            </MDBCarousel>
          </MDBAnimation>
        </section>
      )}
    </Layout>
  )
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "support" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug        
        supportservices {
          title
          subtitle
          info {
            title
            description
            placement
            linktext
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        howhelp {
          title
          subtitle
          custservice {
            title
            alttext
            image {
              childImageSharp {
                fixed(width: 70, height: 70, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        quotes {
          text
        }
      }
      html
    }
  }
`
